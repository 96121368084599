import React from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";
import LOGO from "../assets/logo-home.webp";

const Home: React.FC = () => {
    return (
        <div className="container-home">
            <div className="sub-container-home">
                <div className="logo-sub-container">
                    <img src={LOGO} alt="" />
                </div>
                <div className="infos-container-home">
                    <button className="button-home-container">
                        BÔNUS ENCONTRADO
                    </button>
                    <p>
                        Para resgatar seu bônus, crie uma conta na plataforma da
                        Onabet e depois acesse o aplicativo
                    </p>
                </div>
                <div className="iframe-sub-container">
                    <iframe
                        style={{ width: "100%", height: "100%" }}
                        src="https://onabet.cxclick.com/visit/?bta=55116&amp;brand=onabet"
                        title="Conteúdo Incorporado"
                    />
                </div>
            </div>
            <div className="container-button-home">
                <Link className="w-full" to="/jogos">
                    <button className="button-acessar-app">
                        Acessar aplicativo
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Home;
