import React, { useState, useEffect } from "react";
// import "../../index.css";

const DownloadPWA: React.FC = () => {
    const [installPrompt, setInstallPrompt] = useState<any>(null);

    useEffect(() => {
        const handler = (event: any) => {
            event.preventDefault();
            setInstallPrompt(event);
        };

        window.addEventListener("beforeinstallprompt", handler);

        return () => {
            window.removeEventListener("beforeinstallprompt", handler);
        };
    }, []);

    const handleInstallClick = () => {
        if (installPrompt) {
            installPrompt.prompt();
            installPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("Usuário aceitou a instalação");
                } else {
                    console.log("Usuário rejeitou a instalação");
                }
                setInstallPrompt(null);
            });
        }
    };

    return (
        <div>
            {installPrompt && (
                <button className="button-pwa" onClick={handleInstallClick}>
                    Instalar o PWA
                </button>
            )}
            {!installPrompt && <p>PWA não está disponível para instalação</p>}
        </div>
    );
};

export default DownloadPWA;
