import "../styles/games.css";
import LOGO from "../assets/logo-home.webp";
import tigrinho from "../assets/tigrinho.png";
import ratinho from "../assets/ratinho.png";
import coelhinho from "../assets/coelhinho.png";
import touro from "../assets/touro.png";
import aviator from "../assets/aviator.png.png";
import dragao from "../assets/dragao.png";
import { ProgressCard } from "./ProgressCards";
import { Link } from "react-router-dom";

export const Games = () => {
    return (
        <div className="container-games">
            <div className="background-overlay">
                <div className="sub-container-games">
                    <div className="logo-sub-container-games">
                        <img src={LOGO} alt="" />
                    </div>
                    <div className="texts-centers-games">
                        <h1 className="games-title">
                            CHEGA DE PERDER <br />
                            <span className="color-green">DINHEIRO</span>
                        </h1>
                        <h1 className="sub-title-red-games">
                            APOSTANDO NOS JOGOS ERRADOS
                        </h1>
                    </div>
                    <div className="texts-centers-games">
                        <p className="title-sub-games">
                            Escolha o jogo com a{" "}
                            <span className="span-green">BARRA VERDE</span> para
                            ter a maior assertividade do momento.
                        </p>
                        <p className="title-sub-games">
                            Aproveite os{" "}
                            <span className="span-green"> Apps Gratuitos</span>{" "}
                            enquanto ainda estão disponíveis!
                        </p>
                    </div>
                    <div className="itens-jogos-games">
                        <Link to="/tigrinho">
                            <ProgressCard imageSrc={tigrinho} />
                        </Link>

                        <Link to="/ratinho">
                            <ProgressCard imageSrc={ratinho} />
                        </Link>
                        <Link to="/rabit">
                            <ProgressCard imageSrc={coelhinho} />
                        </Link>
                        <Link to="/touro">
                            <ProgressCard imageSrc={touro} />
                        </Link>
                        <Link to="/dragon">
                            {" "}
                            <ProgressCard imageSrc={dragao} />
                        </Link>
                        <Link to="/aviator">
                            {" "}
                            <ProgressCard imageSrc={aviator} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
