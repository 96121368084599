import React, { useEffect, useState } from "react";
import "../styles/google-play.css";
import header from "../assets/google-play/CABECALHO.jpg.webp";
import menu from "../assets/google-play/MENU.webp";
import googlePlay01 from "../assets/google-play/01.jpg.webp";
import googlePlay02 from "../assets/google-play/02.jpg.webp";
import googlePlay03 from "../assets/google-play/03.jpg.webp";
import game from "../assets/google-play/GAME.jpg.webp";
import tiger1 from "../assets/google-play/tiger-1.webp";
import tiger2 from "../assets/google-play/tiger-2.webp";
import tiger3 from "../assets/google-play/tiger-3.webp";
import tiger4 from "../assets/google-play/tiger-4.webp";
import instalar from "../assets/google-play/INSTALAR.jpg.webp";

export const GooglePlay = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [progress, setProgress] = useState<number>(0);
    const [installPrompt, setInstallPrompt] = useState<any>(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: any) => {
            event.preventDefault();
            setInstallPrompt(event);
        };

        window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );

        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(id);
                    return 100;
                }
                return prev + 1;
            });
        }, 30);

        return () => {
            if (id) clearInterval(id);
        };
    }, []);

    const handleInstallClick = () => {
        if (installPrompt) {
            installPrompt.prompt();
            installPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("Usuário aceitou a instalação");
                } else {
                    console.log("Usuário rejeitou a instalação");
                }
                setInstallPrompt(null);
            });
        }
    };

    return (
        <div className="container-google-play">
            <div className="sub-container-google-play">
                <div className="header-google-play">
                    <img src={header} alt="Header" />
                </div>
                <div className="container-google-play-section">
                    <img src={game} alt="Game" onClick={openModal} />
                    <div className="carousel">
                        <div className="carousel-track">
                            <div className="carousel-item">
                                <img src={tiger1} alt="Tiger 1" />
                            </div>
                            <div className="carousel-item">
                                <img src={tiger2} alt="Tiger 2" />
                            </div>
                            <div className="carousel-item">
                                <img src={tiger3} alt="Tiger 3" />
                            </div>
                            <div className="carousel-item">
                                <img src={tiger4} alt="Tiger 4" />
                            </div>
                        </div>
                    </div>
                    <img src={googlePlay01} alt="Google Play 01" />
                    <img src={googlePlay02} alt="Google Play 02" />
                    <img src={googlePlay03} alt="Google Play 03" />
                </div>
                <div className="menu-google-play">
                    <img src={menu} alt="Menu" />
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button className="modal-close" onClick={closeModal}>
                            X
                        </button>
                        <img src={instalar} alt="Instalar" />
                        <h1>{progress}%</h1>
                        {progress >= 100 && (
                            <button
                                className="button-gradient-install-google-play"
                                onClick={handleInstallClick}
                            >
                                Instalar
                            </button>
                        )}
                        {!installPrompt && (
                            <p>PWA não está disponível para instalação</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
