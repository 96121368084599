import "../styles/games-slots.css";
import INSTALAR from "../assets/games/INSTALAR.jpg.webp";
import { RadomBet } from "../components/RadomBet";
import { ButtonInstallPwa } from "../components/ButtonInstallPwa";
import RABIT from "../assets/games/HACK-FORTUNE-RABBIT.png";

const Rabit = () => {
    return (
        <div className="container-games-slots">
            <div className="background-overlay-games-slots">
                <div className="games-center-games-slots">
                    <div className="games-slots-r-side">
                        <RadomBet imgProps={RABIT} gradient="gradient-yellow" />
                    </div>

                    <div className="games-slots-l-side">
                        <div className="iframe-sub-container-games-slots">
                            <iframe
                                style={{ width: "100%", height: "100%" }}
                                src="https://onabet.com/casino/game/2527279?provider=PGSoft?bta=41504&brand=onabet"
                                title="Conteúdo Incorporado"
                            />
                        </div>
                        <img src={INSTALAR} alt="" />

                        <ButtonInstallPwa />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rabit;
