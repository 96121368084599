import React, { useEffect, useRef, useState } from "react";
import clickSound from "../assets/audios/tigrinho-audio.mp3";
import START from "../assets/iconentrada.webp";
import { Clock, Zap } from "lucide-react";
import "../styles/games-slots.css";

export const RadomBet = ({ imgProps, gradient }: any) => {
    const [normalValue, setNormalValue] = useState<string>("...");
    const [turboValue, setTurboValue] = useState<string>("...");
    const [validUntil, setValidUntil] = useState<string>("...");
    const [assertividade, setAssertividade] = useState<string>("...");
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(0);
    const [audioLoaded, setAudioLoaded] = useState<boolean>(false);

    const audio = new Audio(clickSound);

    const audioRef = useRef<HTMLAudioElement | null>(null);

    const gerarNovoSinal = () => {
        if (audioLoaded && audioRef.current) {
            // Adiciona um atraso de 2 segundos antes de tocar o áudio
            setTimeout(() => {
                audioRef.current?.play();
            }, 1000); // 2000 milissegundos = 2 segundos
        }
        // Desabilita o botão e inicia o efeito de piscar
        setButtonDisabled(true);
        setCountdown(30);

        // Temporariamente mostra os valores como "..." antes de atualizar
        setNormalValue("...");
        setTurboValue("...");
        setValidUntil("...");
        setAssertividade("...");

        // Atualiza os valores após um pequeno delay
        setTimeout(() => {
            // Define valores aleatórios
            const normal = Math.floor(Math.random() * (12 - 6 + 1)) + 6 + "X";
            const turbo = Math.floor(Math.random() * (12 - 6 + 1)) + 6 + "X";
            const now = new Date();
            now.setMinutes(now.getMinutes() + 5);
            const hours = now.getHours().toString().padStart(2, "0");
            const minutes = now.getMinutes().toString().padStart(2, "0");
            const valid = `${hours}:${minutes}`;
            const assertividade =
                (Math.random() * (100 - 98) + 98).toFixed(2) + "%";

            // Faz os valores piscarem três vezes
            let flashCount = 0;
            const intervalId = setInterval(() => {
                setNormalValue((prev) => (prev === "..." ? normal : "..."));
                setTurboValue((prev) => (prev === "..." ? turbo : "..."));
                setValidUntil((prev) => (prev === "..." ? valid : "..."));
                setAssertividade((prev) =>
                    prev === "..." ? assertividade : "..."
                );

                flashCount++;
                if (flashCount >= 6) {
                    // Três piscadas (dois estados por piscada)
                    clearInterval(intervalId);
                    // Inicia o contador após o efeito de piscar
                    setCountdown(30);
                }
            }, 150); // Alterna a cada 250ms

            // Atualiza os valores finais uma vez após o piscar
            setNormalValue(normal);
            setTurboValue(turbo);
            setValidUntil(valid);
            setAssertividade(assertividade);
        }, 1000); // Atraso de 1 segundo antes de atualizar os valores
    };

    useEffect(() => {
        // Função para atualizar o contador
        let intervalId: NodeJS.Timeout;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(intervalId);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } else if (countdown === 0) {
            // Reativa o botão quando o contador chega a zero
            setButtonDisabled(false);
        }

        // Limpa o intervalo quando o componente é desmontado ou a contagem acaba
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [countdown]);

    useEffect(() => {
        // Configura e carrega o áudio
        const audio = new Audio(clickSound);
        audio.preload = "auto";
        audioRef.current = audio;

        const handleCanPlayThrough = () => {
            setAudioLoaded(true);
        };

        if (audioRef.current) {
            audioRef.current.addEventListener(
                "canplaythrough",
                handleCanPlayThrough
            );
            return () => {
                audioRef.current?.removeEventListener(
                    "canplaythrough",
                    handleCanPlayThrough
                );
            };
        }
    }, []);

    return (
        <>
            <div className="games-slots-r-side-absolute">
                <img src={imgProps} alt="" />
            </div>
            <div className={`card-games-slots ${gradient}`}>
                <div className="card-games-slots-form">
                    <div className="content-card-games-slots">
                        <img src={START} alt="" />
                        <div className="title-games-slots">
                            <h1>NORMAL:</h1>
                            <h1 className="green-games-slots">{normalValue}</h1>
                        </div>
                    </div>
                </div>
                <div className="card-games-slots-form">
                    <div className="content-card-games-slots">
                        <div className="circle-games-slots">
                            {" "}
                            <Zap strokeWidth={3} size={20} />
                        </div>
                        <div className="title-games-slots">
                            <h1>TURBO:</h1>
                            <h1 className="green-games-slots">{turboValue}</h1>
                        </div>
                    </div>
                </div>
                <div className="card-games-slots-form">
                    <div className="content-card-games-slots">
                        <div className="circle-games-slots">
                            {" "}
                            <Clock strokeWidth={3} size={20} />
                        </div>
                        <div className="title-games-slots">
                            <h1>VÁLIDO ATÉ:</h1>
                            <h1 className="green-yellow">{validUntil}</h1>
                        </div>
                    </div>
                </div>
                <div className="card-games-slots-form-2">
                    <div className="content-card-games-slots">
                        <div className="title-games-slots">
                            <h1 className="assertividade-text">
                                Assertividade de:
                            </h1>
                            <h1 className="green-games-slots">
                                {assertividade}
                            </h1>
                        </div>
                    </div>
                </div>
                <button
                    className={
                        buttonDisabled
                            ? "button-green-games-slots-disabled"
                            : "button-green-games-slots"
                    }
                    onClick={gerarNovoSinal}
                    disabled={buttonDisabled}
                >
                    {buttonDisabled
                        ? `Aguarde ${countdown}s`
                        : "Gerar novo sinal"}
                </button>
                <div className="contet-games-slots-infos">
                    <p>Funciona apenas no: megabeet.com</p>
                    <p>Multiplicador de 2.500X Ativado</p>
                </div>
            </div>
        </>
    );
};
