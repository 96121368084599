import "../styles/games-slots-crash.css";
import INSTALAR from "../assets/games/INSTALAR.jpg.webp";
import { ButtonInstallPwa } from "../components/ButtonInstallPwa";
import AVIATOR from "../assets/aviator.webp";
import { RadomBetCrash } from "../components/RadomBetCrash";

const Aviator = () => {
    return (
        <div className="container-games-slots-crash">
            <div className="background-overlay-games-slots-crash">
                <div className="games-center-games-slots-crash">
                    <div className="games-slots-crash-r-side">
                        <RadomBetCrash imgProps={AVIATOR} />
                    </div>

                    <div className="games-slots-crash-l-side">
                        <div className="iframe-sub-container-games-slots-crash">
                            <iframe
                                style={{ width: "100%", height: "100%" }}
                                src="https://onabet.com/casino/game/2527279?provider=PGSoft?bta=41504&brand=onabet"
                                title="Conteúdo Incorporado"
                            />
                        </div>
                        <img src={INSTALAR} alt="" />

                        <ButtonInstallPwa />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Aviator;
