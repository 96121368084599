import React, { useEffect, useRef, useState } from "react";
import clickSound from "../assets/audios/tigrinho-audio.mp3";
import START from "../assets/iconentrada.webp";
import { Clock, Zap } from "lucide-react";
import "../styles/games-slots-crash.css";

export const RadomBetCrash = ({ imgProps, gradient }: any) => {
    const [audioLoaded, setAudioLoaded] = useState<boolean>(false);
    const [crashValue, setCrashValue] = useState<number>(3);
    const [multiplierValue, setMultiplierValue] = useState<number>(1.18);

    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        // Configura e carrega o áudio
        const audio = new Audio(clickSound);
        audio.preload = "auto";
        audioRef.current = audio;

        const handleCanPlayThrough = () => {
            setAudioLoaded(true);
        };

        if (audioRef.current) {
            audioRef.current.addEventListener(
                "canplaythrough",
                handleCanPlayThrough
            );
            return () => {
                audioRef.current?.removeEventListener(
                    "canplaythrough",
                    handleCanPlayThrough
                );
            };
        }
    }, []);

    const generateSignal = () => {
        // Gera valores aleatórios para os sinais
        const randomCrash = Math.floor(Math.random() * 4) + 1;
        const randomMultiplier = (Math.random() * (1.99 - 1.1) + 1.1).toFixed(
            2
        );

        setCrashValue(randomCrash);
        setMultiplierValue(parseFloat(randomMultiplier));

        // Reproduz o som se o áudio estiver carregado
        if (audioLoaded && audioRef.current) {
            audioRef.current.currentTime = 0; // Reinicia o som para o início
            audioRef.current.play();
        }
    };

    return (
        <>
            <div className="card-games-slots-crash">
                <div className="games-slots-crash-r-side-absolute">
                    <img src={imgProps} alt="" />
                </div>

                <div className="items-crash-games">
                    <h1>AGUARDANDO</h1>
                    <h3>Deixe o avião cair esse número de vezes:</h3>

                    <h3 className="color-red-text">💥 {crashValue}x 💥</h3>

                    <h3>Pegar sinal e dar ca$h no X:</h3>

                    <h3 className="color-red-text">🛫 {multiplierValue}x 🛫</h3>
                </div>
                <button
                    className="button-generator-signal-crash"
                    onClick={generateSignal}
                >
                    GERAR SINAL
                </button>
            </div>
        </>
    );
};
