import React, { useEffect, useState } from "react";

export const ButtonInstallPwa = () => {
    const [progress, setProgress] = useState<number>(0);
    const [installPrompt, setInstallPrompt] = useState<any>(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: any) => {
            event.preventDefault();
            setInstallPrompt(event);
        };

        window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );

        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(id);
                    return 100;
                }
                return prev + 1;
            });
        }, 30);

        return () => {
            if (id) clearInterval(id);
        };
    }, []);

    const handleInstallClick = () => {
        if (installPrompt) {
            installPrompt.prompt();
            installPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("Usuário aceitou a instalação");
                } else {
                    console.log("Usuário rejeitou a instalação");
                }
                setInstallPrompt(null);
            });
        }
    };

    return (
        <div className="content-fim">
            <h1>{progress}%</h1>
            <div>
                {progress >= 100 && (
                    <button
                        className="button-gradient-install"
                        onClick={handleInstallClick}
                    >
                        Instalar
                    </button>
                )}
            </div>
        </div>
    );
};
