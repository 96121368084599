import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import DownloadPWA from "./pages/DownloadPWA";
import { Games } from "./pages/Games";
import Tigrinho from "./pages/Tigrinho";
import Ratinho from "./pages/Ratinho";
import Rabit from "./pages/Rabit";
import Touro from "./pages/Touro";
import Dragon from "./pages/Dragon";
import Aviator from "./pages/Aviator";
import { GooglePlay } from "./pages/GooglePlay";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/download" element={<DownloadPWA />} />
                <Route path="/jogos" element={<Games />} />
                <Route path="/tigrinho" element={<Tigrinho />} />
                <Route path="/ratinho" element={<Ratinho />} />
                <Route path="/rabit" element={<Rabit />} />
                <Route path="/touro" element={<Touro />} />
                <Route path="/dragon" element={<Dragon />} />
                <Route path="/aviator" element={<Aviator />} />
                <Route path="/google-play" element={<GooglePlay />} />

            </Routes>
        </Router>
    );
};

export default App;
