import { useEffect, useState } from "react";

export const ProgressCard: React.FC<{ imageSrc: string }> = ({ imageSrc }) => {
    const [progress, setProgress] = useState(() => Math.random() * 60);
    const [colorClass, setColorClass] = useState("");

    useEffect(() => {
        const updateColorClass = (progressValue: number) => {
            if (progressValue <= 30) {
                setColorClass("red");
            } else if (progressValue > 30 && progressValue <= 60) {
                setColorClass("yellow");
            } else {
                setColorClass("green");
            }
        };

        const incrementProgress = () => {
            setProgress((prevProgress) => {
                const increment = Math.random() * 0.2 + 0.1; // Incremento entre 1 e 4
                const newProgress = Math.min(prevProgress + increment, 100); // Não ultrapassa 100%
                updateColorClass(newProgress);
                if (newProgress === 100) {
                    setTimeout(() => setProgress(0), 1000); // Pausa de 1 segundo antes de reiniciar
                }
                return newProgress;
            });
        };

        updateColorClass(progress);

        const interval = setInterval(() => {
            if (progress < 100) {
                incrementProgress();
            } else {
                clearInterval(interval);
            }
        }, Math.random() * 500 + 500); // Intervalo entre 500ms e 1s

        return () => clearInterval(interval);
    }, [progress]);

    return (
        <div className="itens-games-container">
            <div className="img-jogos-screan">
                <img src={imageSrc} alt="Game Image" />
            </div>
            <div className="progress">
                <div
                    className={`progress-bar ${colorClass}`}
                    style={{ width: `${progress}%` }}
                />
                <span className="progress-text">{`${progress.toFixed(
                    2
                )}%`}</span>
            </div>
        </div>
    );
};
