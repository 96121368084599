import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("Service Worker registrado:", registration);
            })
            .catch((error) => {
                console.error("Falha ao registrar o Service Worker:", error);
            });
    });
}

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

reportWebVitals();
